import React, { useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import "bootstrap/dist/css/bootstrap.min.css";
import API from "../../api";
import { toast, ToastContainer } from "react-toastify";

const arrayFund = [
  {
    name: "Virtual Account",
    is_active: 1,
    child: [
      {
        code: "014",
        name: "BCA",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/bca-logo.svg",
      },
      {
        code: "008",
        name: "Mandiri",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/mandiri-logo.svg",
      },
      {
        code: "002",
        name: "BRI",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/bri-logo.svg",
      },
      {
        code: "009",
        name: "BNI",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/bni-logo.svg",
      },
      {
        code: "013",
        name: "Permata",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/permata-logo.svg",
      },
      {
        code: "011",
        name: "Danamon",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/danamon-logo.svg",
      },
      {
        code: "022",
        name: "CIMB",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/cimb-logo.svg",
      },
    ],
  },
  {
    name: "Qris",
    is_active: 1,
    child: [],
    image:
      "https://storage.googleapis.com/nero-assets/payment-method/qris-logo.svg",
  },
  {
    name: "E-Wallet",
    is_active: 1,
    child: [
      {
        code: "ovo",
        name: "OVO",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/ovo-logo.svg",
      },
      {
        code: "dana",
        name: "DANA",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/dana-logo.svg",
      },
      {
        code: "shopeepay",
        name: "SHOPEEPAY",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/shopeepay-logo.svg",
      },
      {
        code: "gopay",
        name: "GOPAY",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/gopay-logo.svg",
      },
    ],
  },
];

const getCurrentDateTime = () => {
  return new Date().toISOString();
};

const Payment = () => {
  const { userID } = useParams();
  const location = useLocation();
  const history = useHistory();
  const userData = location.state;
  const [paymentMethodDetails, setPaymentMethodDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!userData) {
    return <p>Invalid access</p>;
  }

  const handleBack = () => {
    history.push("/top-up", userData);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log("Form submitted with values:", values);

    const selectedParent = arrayFund.find(
      (method) =>
        method.child.some((child) => child.code === values.paymentMethod) ||
        method.name === values.paymentMethod
    );
    const parentMethodName = selectedParent
      ? selectedParent.name
      : values.paymentMethod;
    const selectedCode =
      selectedParent && selectedParent.child.length > 0
        ? values.paymentMethod
        : null;

    const user_id_nero = localStorage.getItem('user_id_nero');

    const submissionData = {
      name: userData.username,
      user_id: userData.userID,
      user_id_nero: user_id_nero,
      ref_id: userData.ref_id || "", // Add ref_id to submission data
      game_id: userData.game,
      amount: userData.amount,
      phone_number: userData.phoneNumber,
      payment_method: parentMethodName,
      code: selectedCode,
      merchant_id: null,
      transaction_id: null,
      payment_date: getCurrentDateTime(),
      requested_date: getCurrentDateTime(),
    };

    console.log("Submitting data to API:", submissionData);

    try {
      const response = await API.post("payments", submissionData);
      console.log("API response:", response);

      if (response.status && response.status === "Success") {
        let details;
        if (parentMethodName === "Virtual Account") {
          details = response.data.responseData.virtualAccount;
        } else if (parentMethodName === "Qris") {
          details = response.data.responseData.qris;
        } else if (parentMethodName === "E-Wallet") {
          details = response.data.responseData.eWallet;
        } else {
          details = response.data.responseData;
        }
        setPaymentMethodDetails(details);
        history.push({
          pathname: `/confirmation/${response.data.responseData.id}`,
          state: {
            userData: {
              ...submissionData,
              status: response.status,
              transaction_id: response.data.responseData.id,
            },
            paymentDetails: details,
          },
        });
      } else {
        toast.error(response.message || "An error occurred", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error during payment processing", error);
      toast.error("An error occurred during payment processing", {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payment-page">
      <div className="payment-wrapper">
        {loading && (
          <div className="modal-overlay">
            <div className="infinity-10"></div>
          </div>
        )}
        <div className={`content ${loading ? "loading" : ""}`}>
          <div className="line-1"></div>
          <div className="amount-container">
            <span>{userData.amount} Coin</span>
            <span>IDR {userData.amount}</span>
          </div>
          <div className="line-1"></div>
          <div className="amount-container">
            <span>
              <strong>Total</strong>
            </span>
            <span>IDR {userData.amount}</span>
          </div>
          <div className="line-1" style={{ marginBottom: 40 }}></div>
          <div className="user-info" style={{ marginBottom: 40 }}>
            <div className="user-wrapper">
              <div className="title-form w-100 b-1">
                <div
                  className="d-flex w-100 gap-15"
                  style={{ gap: 60, padding: "5px 0px" }}
                >
                  <p>Username</p>
                  <p style={{ color: "black" }}>{userData.username}</p>
                </div>
                <div className="line-1"></div>
                <div
                  className="d-flex w-100 gap-25"
                  style={{ padding: "5px 0px" }}
                >
                  <p>Phone Number</p>
                  <p style={{ color: "black" }}>{userData.phoneNumber}</p>
                </div>
                <div className="line-1"></div>
                <div
                  className="d-flex w-100"
                  style={{ gap: 70, padding: "5px 0px" }}
                >
                  <p>Game ID</p>
                  <p style={{ color: "black" }}>{userData.game}</p>
                </div>
                <div className="line-1"></div>
                <div
                  className="d-flex w-100"
                  style={{ gap: 75, padding: "5px 0px" }}
                >
                  <p>Amount</p>
                  <p style={{ color: "black" }}>{userData.amount}</p>
                </div>
              </div>
            </div>
          </div>

          <h4 style={{ color: "black" }}>Select Payment Method</h4>
          <Formik
            initialValues={{
              paymentMethod: "",
              cardNumber: "",
              expiryDate: "",
              cvv: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Accordion defaultActiveKey="0">
                  {arrayFund.map((method, idx) => (
                    <Accordion.Item eventKey={idx.toString()} key={idx}>
                      <Accordion.Header>
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex flex-column">
                            <span>{method.name}</span>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {method.child.length > 0 ? (
                          <ul className="grid-template">
                            {method.child.map((child, cidx) => (
                              <li
                                key={cidx}
                                style={{ listStyle: "none", marginBottom: 10 }}
                              >
                                <label
                                  htmlFor={child.code}
                                  style={{ marginLeft: 8 }}
                                  className="radio-card"
                                >
                                  <Field
                                    type="radio"
                                    name="paymentMethod"
                                    value={child.code}
                                    id={child.code}
                                  />
                                  <div className="card-content-wrapper height-170">
                                    <span className="check-icon"></span>
                                    <div className="card-content">
                                      <img
                                        src={child.image}
                                        alt={child.name}
                                        style={{
                                          width: 80,
                                          height: 80,
                                          marginRight: 10,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </label>
                              </li>
                            ))}
                          </ul>
                        ) : method.name === "Qris" ? (
                          <label
                            htmlFor={method.name}
                            style={{ marginLeft: 8 }}
                            className="radio-card"
                          >
                            <Field
                              type="radio"
                              name="paymentMethod"
                              value={method.name}
                              id={method.name}
                              className="form-check-input"
                            />
                            <div className="card-content-wrapper height-170">
                              <span className="check-icon"></span>
                              <div className="card-content">
                                <img
                                  src={method.image}
                                  alt="QRIS"
                                  style={{ width: 100, height: 100 }}
                                />
                              </div>
                            </div>
                          </label>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
                <div
                  style={{
                    justifyContent: "center",
                    marginTop: "20px",
                    display: "flex",
                    gap: 20,
                  }}
                >
                  <Button className="black" type="button" onClick={handleBack}>
                    Back
                  </Button>
                  <Button className="black" type="submit">
                    Pay Now
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Payment;

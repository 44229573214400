import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import API from "../../api";
import Accordion from "react-bootstrap/Accordion";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/style.css";
import productsConfig from "../../data/productConfig";

const arrayFund = [
  {
    name: "Virtual Account",
    is_active: 1,
    child: [
      {
        code: "014",
        name: "BCA",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/bca-logo.svg",
      },
      {
        code: "008",
        name: "Mandiri",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/mandiri-logo.svg",
      },
      {
        code: "002",
        name: "BRI",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/bri-logo.svg",
      },
      {
        code: "009",
        name: "BNI",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/bni-logo.svg",
      },
      {
        code: "013",
        name: "Permata",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/permata-logo.svg",
      },
      {
        code: "011",
        name: "Danamon",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/danamon-logo.svg",
      },
      {
        code: "022",
        name: "CIMB",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/cimb-logo.svg",
      },
    ],
  },
  {
    name: "Qris",
    is_active: 1,
    child: [],
    image:
      "https://storage.googleapis.com/nero-assets/payment-method/qris-logo.svg",
  },
  {
    name: "E-Wallet",
    is_active: 1,
    child: [
      {
        code: "ovo",
        name: "OVO",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/ovo-logo.svg",
      },
      {
        code: "dana",
        name: "DANA",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/dana-logo.svg",
      },
      {
        code: "shopeepay",
        name: "SHOPEEPAY",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/shopeepay-logo.svg",
      },
      {
        code: "gopay",
        name: "GOPAY",
        is_active: 1,
        image:
          "https://storage.googleapis.com/nero-assets/payment-method/gopay-logo.svg",
      },
    ],
  },
];

const getImageByDiamondCount = (gameName, diamondCount) => {
  if (
    gameName === "PUBG Mobile (Indonesia)" ||
    gameName === "PUBG Mobile (Global)"
  ) {
    const count = parseInt(diamondCount, 10);
    if (typeof diamondCount === "string") {
      if (diamondCount.includes("Upgrade RP"))
        return "https://storage.googleapis.com/nero-assets/pubg/pubg_rp.webp";
      if (diamondCount.includes("Upgrade Elite RP"))
        return "https://storage.googleapis.com/nero-assets/pubg/pubg_elite_rp.webp";
    }
    if (count >= 1 && count <= 120)
      return "https://storage.googleapis.com/nero-assets/pubg/pubg_1.webp";
    if (count >= 300 && count <= 600)
      return "https://storage.googleapis.com/nero-assets/pubg/pubg_2.webp";
    if (count >= 660 && count <= 950)
      return "https://storage.googleapis.com/nero-assets/pubg/pubg_3.webp";
    if (count >= 1000 && count <= 1800)
      return "https://storage.googleapis.com/nero-assets/pubg/pubg_4.webp";
    if (count >= 2000 && count <= 100000)
      return "https://storage.googleapis.com/nero-assets/pubg/pubg_5.webp";
  } else if (gameName === "Mobile Legends") {
    if (typeof diamondCount === "string") {
      if (diamondCount.includes("Weekly Diamond Pass"))
        return "https://storage.googleapis.com/nero-assets/mlbb/weekly-mlbb.webp";
      if (diamondCount.includes("Twilight Pass"))
        return "https://storage.googleapis.com/nero-assets/mlbb/twilight-mlbb.webp";
    }
    const count = parseInt(diamondCount, 10);
    if (count >= 1 && count <= 127)
      return "https://storage.googleapis.com/nero-assets/mlbb/small-diamond-mlbb.webp";
    if (count >= 128 && count <= 499)
      return "https://storage.googleapis.com/nero-assets/mlbb/medium-diamond-mlbb.webp";
    if (count >= 500 && count <= 966)
      return "https://storage.googleapis.com/nero-assets/mlbb/big-diamond-mlbb.webp";
    if (count >= 967 && count <= 2625)
      return "https://storage.googleapis.com/nero-assets/mlbb/vault-diamond-mlbb.webp";
    if (count >= 3453 && count <= 9660)
      return "https://storage.googleapis.com/nero-assets/mlbb/truck-diamond-mlbb.webp";
    if (count >= 10050)
      return "https://storage.googleapis.com/nero-assets/mlbb/bank-diamond-mlbb.webp";
  } else if (gameName === "Free Fire") {
    const count = parseInt(diamondCount, 10);
    if (typeof diamondCount === "string") {
      if (diamondCount.includes("Member Mingguan"))
        return "https://storage.googleapis.com/nero-assets/freefire/free_fire_weekly.webp";
      if (diamondCount.includes("Member Bulanan"))
        return "https://storage.googleapis.com/nero-assets/freefire/free_fire_monthly.webp";
      if (diamondCount.includes("Level Up Pass"))
        return "https://storage.googleapis.com/nero-assets/freefire/free_fire_weekly.webp";
      if (diamondCount.includes("BP Card"))
        return "https://storage.googleapis.com/nero-assets/freefire/free_fire_monthly.webp";
    }
    if (count >= 5 && count <= 80)
      return "https://storage.googleapis.com/nero-assets/freefire/free_fire_xs.webp";
    if (count >= 100 && count <= 355)
      return "https://storage.googleapis.com/nero-assets/freefire/free_fire_s.webp";
    if (count >= 425 && count <= 635)
      return "https://storage.googleapis.com/nero-assets/freefire/free_fire_m.webp";
    if (count >= 720 && count <= 1075)
      return "https://storage.googleapis.com/nero-assets/freefire/free_fire_l.webp";
    if (count >= 1080 && count <= 73100)
      return "https://storage.googleapis.com/nero-assets/freefire/free_fire_xl.webp";
  } else if (gameName === "Blood Strike") {
    const count = parseInt(diamondCount, 10);
    if (typeof diamondCount === "string") {
      if (diamondCount.includes("Strike Pass (Elite)"))
        return "https://storage.googleapis.com/nero-assets/bloodstrike/blood_strike_pass_elite.webp";
      if (diamondCount.includes("Strike Pass (Premium)"))
        return "https://storage.googleapis.com/nero-assets/bloodstrike/blood_strike_pass_premium.webp";
    }
    if (count === 100)
      return "https://storage.googleapis.com/nero-assets/bloodstrike/blood_strike_xs.webp";
    if (count === 300)
      return "https://storage.googleapis.com/nero-assets/bloodstrike/blood_strike_s.webp";
    if (count === 500)
      return "https://storage.googleapis.com/nero-assets/bloodstrike/blood_strike_m.webp";
    if (count === 1000)
      return "https://storage.googleapis.com/nero-assets/bloodstrike/blood_strike_l.webp";
    if (count === 2000)
      return "https://storage.googleapis.com/nero-assets/bloodstrike/blood_strike_xl.webp";
    if (count === 5000)
      return "https://storage.googleapis.com/nero-assets/bloodstrike/blood_strike_xxl.webp";
  } else {
    return "https://storage.googleapis.com/nero-assets/coin/stack-of-gold-coins-2.png";
  }
  return "";
};

const LoadingSkeleton = () => (
  <div className="product-container shop-area">
    <div className="product-wrapper">
      <div className="header-grid">
        <div className="header-wrapper">
          <div className="header-banner">
            <Skeleton height={200} />
          </div>
          <div className="product-image">
            <Skeleton circle={true} height={150} width={150} />
            <h4>
              <Skeleton width={100} />
            </h4>
          </div>
        </div>
        <div className="product-description">
          <h2>Cara Top Up</h2>
          <Skeleton count={5} />
        </div>
      </div>
      <div className="product-content-wrapper">
        <div className="content-header">
          <h2>Pilih Nominal</h2>
        </div>
        <div className="content-product">
          <Skeleton count={5} height={100} />
        </div>
      </div>
    </div>
  </div>
);

const addMargin = (amount) => amount * 1.05;

const Loading = () => <div className="text-black">Loading...</div>;
const Error = ({ message }) => <div>{message}</div>;
const GameNotFound = () => <div>Game not found</div>;
const ConfigNotFound = () => <div>Game configuration not found</div>;

const ProductHeader = ({ game }) => (
  <div className="header-grid">
    <div className="header-wrapper">
      <div className="header-banner">
        <div className="banner">
          <img
            style={{ width: "100%" }}
            src="https://storage.googleapis.com/nero-assets/promo-banner/promo-harga-terbaik-nero-games.webp"
            alt="Banner"
          />
        </div>
      </div>
      <div className="product-image">
        <img src={game.image} alt={game.name} />
        <h4 className="text-black text-lg font-semibold">{game.name}</h4>
      </div>
    </div>
    <div className="product-description">
      <h2 className="text-black">Cara Top Up</h2>
      {productsConfig[game.name].caraTopUp.length > 0 ? (
        productsConfig[game.name].caraTopUp.map((step, index) => (
          <p key={index}>
            {index + 1}. {step}
          </p>
        ))
      ) : (
        <p>No steps available for this game.</p>
      )}
    </div>
  </div>
);

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

const ProductForm = ({ game, denominations, gameConfig, onSubmit }) => {
  const [selectedPrice, setSelectedPrice] = useState(0);

  useEffect(() => {
  }, [selectedPrice]);

  const userIdNero = localStorage.getItem("user_id_nero"); // Assuming the user ID is stored in localStorage

  return (
    <Formik
      initialValues={{
        selectedDenom: "",
        userID: "",
        zoneID: "",
        phoneNumber: "",
        paymentMethod: "",
        ref_id: "",
      }}
      validationSchema={gameConfig.schema}
      onSubmit={(values) => onSubmit({ ...values, user_id_nero: userIdNero })}
    >
      {({ handleSubmit, values }) => (
        <Form onSubmit={handleSubmit}>
          <div className="grid-wrapper grid-col-auto">
            {denominations.map((item, index) => {
              const diamondCount = item.package;
              const imageUrl = getImageByDiamondCount(game.name, diamondCount);
              const priceWithMargin = addMargin(item.price);

              return (
                <label
                  key={item.id}
                  htmlFor={`radio-card-${index}`}
                  className="radio-card"
                >
                  <Field
                    type="radio"
                    name="selectedDenom"
                    id={`radio-card-${index}`}
                    value={item.package}
                    onClick={() => setSelectedPrice(priceWithMargin)}
                  />
                  <div className="card-content-wrapper">
                    <span className="check-icon"></span>
                    <div className="card-content">
                      <img src={imageUrl} alt={item.package} />
                      <h4>{item.package}</h4>
                      <h5>Price: {formatCurrency(priceWithMargin)}</h5>
                    </div>
                  </div>
                </label>
              );
            })}
          </div>
          <ErrorMessage
            name="selectedDenom"
            component="div"
            className="text-danger danger"
          />

          <div className="form-user-id">
            <div className="account-detail">
              <div className="number-symbol border-none">2</div>
              <h2 className="text-black">Masukan Detail Akun</h2>
            </div>
            <div className="form-container-product">
              {gameConfig.formFields
                .filter(
                  (field) =>
                    field.name !== "phoneNumber" && field.name !== "ref_id"
                )
                .map((field) => (
                  <div key={field.name} className={`${field.name} flex flex-col items-center justify-center gap-1`}>
                    <label className={`${field.name} text-black`}>
                      {field.label}
                      {field.type === "select" ? (
                        <Field as="select" name="zoneID" className="Select">
                        <option value="">Select Server ID</option>
                        {field.options.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                      
                      ) : (
                        <Field type={field.type} name={field.name} className="border-1 border-gray-700" />
                      )}
                    <ErrorMessage
                      name={field.name}
                      component="div"
                      className={`${field.name}-text-danger danger pb-10 text-xs`}
                    />
                    </label>
                  </div>
                ))}
            </div>
          </div>

          <div className="form-user-id">
            <div className="account-detail">
              <div className="number-symbol border-none">3</div>
              <h2 className="text-black">Detail Informasi Tambahan</h2>
            </div>
            <div className="form-container-product">
              <div className="phoneNumber">
                <label className="phoneNumber text-black">
                  Phone Number
                  <Field type="text" name="phoneNumber" className="border-1 border-gray-700" />
                </label>
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="phoneNumber-text-danger danger"
                />
              </div>
              <div className="ref_id">
                <label className="ref_id text-black">
                  Ref ID (Optional)
                  <Field type="text" name="ref_id" className="border-1 border-gray-700" />
                </label>
                <ErrorMessage
                  name="ref_id"
                  component="div"
                  className="ref_id-text-danger danger"
                />
              </div>
            </div>
          </div>

          <h2 style={{ marginTop: 20, color: "white", fontWeight: "bolder" }} className="text-gray-900 bg-white p-4 mb-2 mt-5 rounded-lg font-medium">
            Pilih Metode Pembayaran
          </h2>
          <Accordion defaultActiveKey="0">
            {arrayFund.map((method, idx) => {
              const isVirtualAccountDisabled = selectedPrice < 10000;
              const isQrisDisabled = selectedPrice < 5000;
              const isEWalletDisabled = selectedPrice < 1000;

              const getMethodMessage = (methodName) => {
                switch (methodName) {
                  case "Virtual Account":
                    return isVirtualAccountDisabled
                      ? "This method is disabled for amounts less than 10,000"
                      : "";
                  case "Qris":
                    return isQrisDisabled
                      ? "This method is disabled for amounts less than 5,000"
                      : "";
                  case "E-Wallet":
                    return isEWalletDisabled
                      ? "This method is disabled for amounts less than 1,000"
                      : "";
                  default:
                    return "";
                }
              };

              return (
                <Accordion.Item eventKey={idx.toString()} key={idx}>
                  <Accordion.Header>{method.name}</Accordion.Header>
                  <Accordion.Body>
                    {getMethodMessage(method.name) ? (
                      <p style={{ color: "red" }}>
                        {getMethodMessage(method.name)}
                      </p>
                    ) : method.child.length > 0 ? (
                      <ul className="grid-template">
                        {method.child.map((child, cidx) => (
                          <li
                            key={cidx}
                            style={{ listStyle: "none", marginBottom: 10 }}
                          >
                            <label
                              htmlFor={child.code}
                              style={{ marginLeft: 8 }}
                              className="radio-card"
                            >
                              <Field
                                type="radio"
                                name="paymentMethod"
                                value={child.code}
                                id={child.code}
                              />
                              <div className="card-content-wrapper height-170">
                                <span className="check-icon"></span>
                                <div className="card-content">
                                  <img
                                    src={child.image}
                                    alt={child.name}
                                    style={{
                                      width: 80,
                                      height: 80,
                                      marginRight: 10,
                                    }}
                                  />
                                </div>
                              </div>
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : method.name === "Qris" ? (
                      <label
                        htmlFor={method.name}
                        style={{ marginLeft: 8 }}
                        className="radio-card"
                      >
                        <Field
                          type="radio"
                          name="paymentMethod"
                          value={method.name}
                          id={method.name}
                          className="form-check-input"
                        />
                        <div className="card-content-wrapper height-170">
                          <span className="check-icon"></span>
                          <div className="card-content">
                            <img
                              src={method.image}
                              alt="QRIS"
                              style={{ width: 100, height: 100 }}
                            />
                          </div>
                        </div>
                      </label>
                    ) : null}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
          <ErrorMessage
            name="paymentMethod"
            component="div"
            className="text-danger danger"
          />
          <div
            className="button-container"
            style={{
              justifyContent: "center",
              marginTop: "20px",
              display: "flex",
              gap: 20,
            }}
          >
            <Button id="submit-btn-voucher" className="black" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const Product = () => {
  const { gameName } = useParams();
  const history = useHistory();
  const [game, setGame] = useState(null);
  const [denominations, setDenominations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDenominations = async () => {
      try {
        const response = await API.get("/retrieveDTU");
        if (response.status === 200) {
          const selectedGame = response.data.response.list_dtu.find(
            (product) => product.name === gameName
          );
          setGame(selectedGame);
          setDenominations(selectedGame.denom);
        } else {
          setError(response.data.message || "Failed to fetch denominations");
        }
      } catch (error) {
        setError("An error occurred while fetching denominations");
      } finally {
        setLoading(false);
      }
    };

    fetchDenominations();
  }, [gameName]);

  const handleSubmit = async (values) => {
    setLoading(true);

    // Adjust the game_id for specific games
    if (gameName === "Mobile Legends") {
      values.game_id = "mobilelegend";
    } else if (gameName === "Free Fire") {
      values.game_id = "freefire";
    } else {
      // Set the game_id to the game name for other games
      values.game_id = gameName;
    }

    const selectedParent = arrayFund.find(
      (method) =>
        method.child.some((child) => child.code === values.paymentMethod) ||
        method.name === values.paymentMethod
    );
    const parentMethodName = selectedParent
      ? selectedParent.name
      : values.paymentMethod;
    const selectedCode =
      selectedParent && selectedParent.child.length > 0
        ? values.paymentMethod
        : null;
    const selectedDenom = denominations.find(
      (d) => d.package === values.selectedDenom
    );

    const submissionData = {
      name: values.username,
      user_id: values.userID,
      user_id_nero: values.user_id_nero, // Add user_id_nero to submission data
      game_id: values.game_id,
      amount: selectedDenom.price,
      phone_number: values.phoneNumber,
      payment_method: parentMethodName,
      code: selectedCode,
      merchant_id: null,
      transaction_id: null,
      payment_date: new Date().toISOString(),
      requested_date: new Date().toISOString(),
      server_id: values.zoneID,
      package: selectedDenom.package,
      ref_id: values.ref_id, // Add ref_id to submission data
    };

    try {
      const response = await API.post("payments", submissionData);
      if (response.status === "Success") {
        let details;
        if (parentMethodName === "Virtual Account") {
          details = response.data.responseData.virtualAccount;
        } else if (parentMethodName === "Qris") {
          details = response.data.responseData.qris;
        } else if (parentMethodName === "E-Wallet") {
          details = response.data.responseData.eWallet;
        } else {
          details = response.data;
        }
        history.push({
          pathname: `/confirmation/${response.data.responseData.merchantRef}`,
          state: {
            userData: {
              ...submissionData,
              status: response.status,
              transaction_id: response.data.responseData.merchantRef,
            },
            paymentDetails: details,
          },
        });
      } else {
        toast.error(response.message || "An error occurred", {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error("An error occurred during payment processing", {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error message={error} />;
  if (!game) return <GameNotFound />;
  const gameConfig = productsConfig[game.name];
  if (!gameConfig) return <ConfigNotFound />;

  return (
    <div className="product-container shop-area">
      <div className="product-wrapper">
        <ProductHeader game={game} />
        <div className="product-content-wrapper">
          <div className="content-header flex items-center justify-start">
            <div className="number-symbol border-none">1</div>
            <h2 className="text-black text-[27.2px]">Pilih Nominal</h2>
          </div>
          <div className="content-product">
            <ProductForm
              game={game}
              denominations={denominations}
              gameConfig={gameConfig}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Product;

const gameData = [
  {
    id: 'sugarpuffs',
    name: 'SUGAR PUFF RUSH',
    description: 'Description for SUGAR game',
    url: 'https://advance-genre-425305-g1.web.app/sugarpuff/', // Adjust the path according to your structure
    price: 'Free', // Example field
    releaseDate: '2022-01-01', // Example field
    width: '1920px',
    height: '1080px',
    transform: 'scale(0.6)',
  },
  {
    id: 'idlemarkets',
    name: 'IDLE MARKET',
    description: 'Description for IDLE game',
    url: 'https://advance-genre-425305-g1.web.app/idlemarket/',
    price: 'Free',
    releaseDate: '2021-06-15',
    width: '1920px',
    height: '1080px',
    transform: 'scale(0.6)',
  },
  {
    id: 'jetpacks',
    name: 'JETWING RIDE',
    description: 'Description for JET PACK game',
    url: 'https://advance-genre-425305-g1.web.app/jetpack/',
    price: '$10',
    releaseDate: '2019-08-20',
    width: '1280px',
    height: '720px',
    transform: 'scale(0.8)',
  },
  {
    id: 'bitcoinclickers',
    name: 'BITCOIN MILLIONAIRE',
    description: 'Description for BITCOIN CLICKER game',
    url: 'https://advance-genre-425305-g1.web.app/bitcoinclicker/',
    price: '$30',
    releaseDate: '2017-12-12',
    width: '1024px',
    height: '768px',
    transform: 'scale(0.6)',
  },
];

export default gameData;

import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CIcon from "@coreui/icons-react";
import { cilHome } from "@coreui/icons";

function Spage() {
  const location = useLocation();
  const history = useHistory();
  const { userData, paymentDetails } = location.state || { userData: null, paymentDetails: null };
  

  const addMargin = (amount) => Math.round(amount * 1.05);
  if (!userData || !paymentDetails) {
    return <p>Invalid access or missing data.</p>;
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const handleBackToHome = () => {
    history.push("/top-up"); // Redirect to the desired home route
  };
  
  const amountWithMargin = addMargin(userData.amount);

  return (
    <div className="sPage-details">
      <div className="sPage-header">
        <img src="https://storage.googleapis.com/nero-assets/nero-game-logo/nero-logo-grey.png" />
        <p style={{marginTop:20}}>Thank you for your payment. Your transaction has been successfully completed.</p>
      </div>

      <div className="user-detail padding-50 leading-relaxed">
        <h2 className='text-black text-lg md:text-xl'>Informasi Pembelian</h2>
        <p><strong>Username:</strong> {userData.name}</p>
        <p><strong>Phone Number:</strong> {userData.phone_number}</p>
        <p><strong>Game ID:</strong> {userData.game_id}</p>
        <p><strong>Package:</strong> {userData.package}</p>
        <p><strong>Transaction ID:</strong> {userData.transaction_id}</p>
        <p><strong>Amount Paid:</strong> {formatCurrency(amountWithMargin)}</p>
      </div>

      {/* <div className="payment-detail">
        <h2>Payment Details</h2>
        <p><strong>Payment Method:</strong> {userData.payment_method}</p>
        {paymentDetails.vaNumber && (
          <p><strong>VA Number:</strong> {paymentDetails.vaNumber}</p>
        )}
        {paymentDetails.bankCode && (
          <p><strong>Bank Code:</strong> {paymentDetails.bankCode}</p>
        )}
        {paymentDetails.eWallet && paymentDetails.eWallet.channel && (
          <p><strong>E-Wallet Channel:</strong> {paymentDetails.eWallet.channel}</p>
        )}
        {paymentDetails.viewName && (
          <p><strong>View Name:</strong> {paymentDetails.viewName}</p>
        )}
      </div> */}

      <div className="back-to-home">
        <Button onClick={handleBackToHome} className="black">
          <CIcon icon={cilHome} /> Back to Home
        </Button>
      </div>
    </div>
  );
}

export default Spage;
